import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Button, Image, Text, TouchableOpacity, Dimensions, FlatList, ActivityIndicator, RefreshControl, ScrollView, SafeAreaView, Linking } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import BQLogo from '../assets/images/logo/sokna.svg'
import BQLogoW from '../assets/images/logo/sokna2.svg'
import colours from '../config/colours';
import fonts from '../config/fonts'
import Facebook from '../assets/images/social/Facebook.svg';
import WhatsApp from '../assets/images/social/Whatsapp.svg';
import Instagram from '../assets/images/social/Instagram.svg';
import LinkedIn from '../assets/images/social/Linkedin.svg';
import Twitter from '../assets/images/social/Twitter.svg';
import YouTube from '../assets/images/social/Twitter.svg';

const { height, width } = Dimensions.get('window')

const Footer = ( animatedValue, pageNumber) => {
  const navigation = useNavigation();

  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  })
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  const { width, height } = dimensions;

  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener("resize", handleResize, false);
    return () => window.removeEventListener("resize", handleResize);

    //firebase.analytics();
    // eslint-disable-next-line
  }, [width, height]);




  const styles = StyleSheet.create({
    container: {
      backgroundColor: colours.secondary,
      borderTopWidth: 1,
      borderTopColor: colours.secondaryDarkest,
      paddingBottom: width > 1275 ? 50 : 0,
    },
    containerInside: {
      flex: 1,
      width: width < 1280 ? '100%' : 1280,
      alignSelf: 'center'
    },
    containerOne: {
      // flex: 1,
      flexDirection: width > 1000 ? 'row' : 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: 20
    },
    containerLinks: {
      paddingBottom:width > 1000 ?0:10,
      // flex: 1,
      flexDirection: width > 1000 ? 'row' : 'column',
     // justifyContent: width > 1000 ?'center':'space-between',
      alignItems: 'center',
      borderBottomWidth:.4,
      marginHorizontal:20,
      borderBottomColor:colours.secondaryLighter
    },
    containerTwo: {
      // flex: 1,
      flexDirection: width > 1000 ? 'row' : 'column',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    containerLogo: {
      flex:1,
      alignItems:'flex-start',
      paddingHorizontal: 10,
    },
    containerSocial: {
      flexDirection: 'row',
      paddingHorizontal: 15,
    },
    containerSocialIcon: {
      marginTop:width > 1000 ? 0:10,
      marginBottom:width > 1000 ? 0:10,
      flexDirection: 'row',
      paddingHorizontal: 10,
      width:30,
      height:30
    },
    containerPrivacy: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingBottom: 10,
      paddingTop: width > 1000 ? 0 : 10
    },

    containerCopyright: {
      paddingHorizontal: 20,
      //flexDirection: 'row',
      alignItems: 'center',
      paddingBottom: 10

    },
    footerText: {
      paddingVertical:5,
      fontSize: width > 1000 ? 15 : 13,
      color: colours.white,
      textAlign: 'center',
      fontWeight: '300',
      letterSpacing: 0.5,
      fontFamily: fonts.primary
    },

    footerLink: {
      fontSize: width > 1000 ? 12 : 10,
      fontWeight: '300',
      fontFamily: fonts.primary
    },

    containerIcon: {
      marginHorizontal: 4,
      alignItems: 'center'
    },

    textFooterLinks: {
      fontSize: width > 1000 ? 18 : 14,
      lineHeight: 27,
      textAlign: 'center',
      fontWeight: '300',
      fontFamily: fonts.primary,
      paddingHorizontal: 20,
      color: colours.white
    },
    containerMain: {
      backgroundColor: colours.greySecond,
      flexDirection: 'row',
      justifyContent: 'center',
    },

    containerMainMobile: {
      backgroundColor: colours.greySecond,
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: 20,

    },
  });

  return (
    <View>
      {/* <FooterWidgets /> */}

      <View style={styles.container}>
        <View style={styles.containerInside}>


          <View style={styles.containerLinks}>

          <View style={styles.containerLogo}>
              <Image source={BQLogo} style={{ width: pageNumber > 0 ? 230 : 270, height: 100, resizeMode: 'contain' }} />
            </View>


              <TouchableOpacity onPress={() => navigation.navigate('About')}>
                <Text style={styles.textFooterLinks} >
                  {'About Us'}
                </Text>
              </TouchableOpacity>

              <TouchableOpacity onPress={() => navigation.navigate('Services')}>
                <Text style={styles.textFooterLinks} >
                  {'Services'}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => navigation.navigate('Properties',{category:'All'})}>
                <Text style={styles.textFooterLinks} >
                  {'Properties'}
                </Text>
              </TouchableOpacity>
              {/* <TouchableOpacity onPress={() => navigation.navigate('Careers')}>
            <Text style={styles.textFooterLinks} >
              {'Careers'}
            </Text>
          </TouchableOpacity> */}
              <TouchableOpacity onPress={() => navigation.navigate('Contact')}>
                <Text style={styles.textFooterLinks} >
                  {'Contact Us'}
                </Text>
              </TouchableOpacity>



          <View style={styles.containerSocial}>
              <TouchableOpacity onPress={() => Linking.openURL('https://www.linkedin.com/company/bestquestrealestate')}  >
                <View style={styles.containerIcon}>
                  <Image source={LinkedIn} style={styles.containerSocialIcon} /> 
                  </View>
              </TouchableOpacity>

              <TouchableOpacity onPress={() => Linking.openURL('https://facebook.com/bestquestrealestate')} >
                <View style={styles.containerIcon}> <Image source={Facebook} style={styles.containerSocialIcon}/></View>
              </TouchableOpacity>

              {/* <TouchableOpacity onPress={() => Linking.openURL('https://twitter.com/bestquestrealestate')}>
                <View style={styles.containerIcon}>  <Image source={Twitter} style={{width:30}}/></View>
              </TouchableOpacity> */}

              <TouchableOpacity onPress={() => Linking.openURL('https://instagram.com/bestquestrealestate')} >
                <View style={styles.containerIcon}>  <Image source={Instagram} style={styles.containerSocialIcon}/></View>
              </TouchableOpacity>
              
              {/* <TouchableOpacity onPress={() => Linking.openURL('https://www.youtube.com/@bestquestrealestate')} >
                <View style={styles.containerIcon}>  <Image source={YouTube} style={{width:30}} /></View>
              </TouchableOpacity> */}
            </View>




            </View>





          <View style={styles.containerTwo}>

            <View style={styles.containerPrivacy}>
              <TouchableOpacity onPress={() => navigation.navigate('Privacy')}>
                <Text style={styles.textFooterLinks} >
                  Privacy Policy
                </Text>
              </TouchableOpacity>

              <TouchableOpacity onPress={() => navigation.navigate('Terms')}>
                <Text style={styles.textFooterLinks} >
                  Business Terms
                </Text>
              </TouchableOpacity>
            </View>

            <View style={styles.containerCopyright}>
              <Text numberOfLines={1} style={styles.footerText} >
                © Copyright  {new Date().getFullYear()}
              </Text>
              <Text numberOfLines={1} style={styles.footerText} >
                BEST QUEST REAL ESTATE BROKERAGE LLC
              </Text>
            </View>
          </View>
        </View>
        <View style={{ marginBottom: width > 1275 ? 20 : 60 }} />

      </View>

    </View>

  );
};




export default Footer;