

import "typeface-lato"
import "typeface-montserrat"
import "typeface-open-sans"
import "typeface-oswald"
import "typeface-poppins"

const fonts = {
  primary	: 'lato',

  };

  export default fonts;
  