

import React, { useState, useEffect, useContext } from 'react';
import { Image,ScrollView, View, Text, Dimensions, StyleSheet, TouchableOpacity, Linking } from "react-native";
import colours from '../config/colours'
import { useNavigation } from '@react-navigation/native';
import fonts from '../config/fonts';
import BQLogo from '../assets/images/logo/sokna.svg'
import BQLogoW from '../assets/images/logo/sokna2.svg'
import iconClose from '../assets/icons/close.svg'

import Facebook from '../assets/images/social/Facebook.svg';
import WhatsApp from '../assets/images/social/WhatsappIcon.svg';

import Instagram from '../assets/images/social/Instagram.svg';
import LinkedIn from '../assets/images/social/Linkedin.svg';
import Twitter from '../assets/images/social/Twitter.svg';
import YouTube from '../assets/images/social/Twitter.svg';
import LeadFormFill from '../pages/promotions/leadFormFill';

const { height, width } = Dimensions.get('window')

const Menu = ({ route, toggleModal }) => {
  const navigation = useNavigation();

  const [isModalVisibleContact, setModalVisibleContact] = useState(false);
  const [isModalVisible, setModalVisible] = useState(true);



  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  })
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  const { width, height } = dimensions;

  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener("resize", handleResize, false);
    return () => window.removeEventListener("resize", handleResize);

    //firebase.analytics();
    // eslint-disable-next-line
  }, [width, height]);












  const goHome = () => {
    setModalVisible(!isModalVisible);
    navigation.goBack();
  };
  const Home = () => {
    navigation.navigate('Home');
    setModalVisible(!isModalVisible);

  }
  return (

    <ScrollView >



      <TouchableOpacity
        onPress={toggleModal}
      >

        <View style={styles.headerItemLeft}>
        <img src={iconClose} width={35} height={35} />
        </View>

      </TouchableOpacity>



      <View style={styles.containerLogo}>
        <TouchableOpacity onPress={() => { toggleModal(), navigation.navigate('Home') }}>
          <img src={BQLogo} width={250} height={80} />
        </TouchableOpacity>
      </View>


      <TouchableOpacity
        onPress={() => { toggleModal(), navigation.navigate('Home') }}
      >
        <View style={styles.menuContainer}>
          <Text style={styles.menuText}> HOME </Text>
        </View>
      </TouchableOpacity>

      <TouchableOpacity
        onPress={() => { toggleModal(), navigation.navigate('About') }}
      >
        <View style={styles.menuContainer}>
          <Text style={styles.menuText}> ABOUT </Text>
        </View>
      </TouchableOpacity>

      <TouchableOpacity
        onPress={() => { toggleModal(), navigation.navigate('Services') }}
      >
        <View style={styles.menuContainer}>
          <Text style={styles.menuText}> SERVICES </Text>
        </View>
      </TouchableOpacity>

      <TouchableOpacity
        onPress={() => { toggleModal(), navigation.navigate('Properties',{category:'All'}) }}
      >
        <View style={styles.menuContainer}>
          <Text style={styles.menuText}> PROPERTIES </Text>
        </View>
      </TouchableOpacity>


      {/* <TouchableOpacity
        onPress={() => { toggleModal(), navigation.navigate('Careers') }}
      >
        <View style={styles.menuContainer}>
          <Text style={styles.menuText}> CAREER </Text>
        </View>
      </TouchableOpacity> */}


      <TouchableOpacity
        onPress={() => { toggleModal(), navigation.navigate('Contact') }}

      >
        <View style={styles.menuContainer}>
          <Text style={styles.menuText}> CONTACT US </Text>
        </View>
      </TouchableOpacity>






      <View style={styles.containerForm}>

        {/* <Text style={styles.textH2}>
          {'Connect With Us'}
        </Text>
        <Text style={[styles.textParagraph, { color: colours.secondaryLighter }]}>
          {'Request A Callback'}
        </Text>
        <LeadFormFill pageName={'About'} /> */}


        {/* <Text style={styles.textH2}>
          {'Chat With Us'}
        </Text> */}
        {/* <Text style={[styles.textParagraph, { color: colours.secondary }]}>
          {'Start Converstation in WhatsApp'}
        </Text> */}


        <TouchableOpacity style={styles.submitButton} onPress={() => Linking.openURL('https://api.whatsapp.com/send?phone=971507893788')} >
          <View> <Image source={WhatsApp} style={{ width: 40, height: 40 }} /></View>
          {/* <View><Text style={styles.textSubmit}>{'Start Chat'}</Text>  </View> */}
        </TouchableOpacity>

        <View style={{ marginVertical: 10 }} />

      </View>

      <View style={{ marginHorizontal:15,marginVertical: 0,borderRadius:10, borderBottomColor: colours.blueGreyLight, borderBottomWidth: 2 }} />

      <Text style={styles.menuTextFollow}> Follow Us </Text>

      <View style={styles.containerSocial}>

        <TouchableOpacity onPress={() => Linking.openURL('https://www.linkedin.com/company/bestquestrealestate')}  >
          <View style={styles.containerIcon}> <img src={LinkedIn} width={30} /> </View>
        </TouchableOpacity>

        <TouchableOpacity onPress={() => Linking.openURL('https://facebook.com/bestquestrealestate')} >
          <View style={styles.containerIcon}> <img src={Facebook} width={30} /></View>
        </TouchableOpacity>


        <TouchableOpacity onPress={() => Linking.openURL('https://instagram.com/bestquestrealestate')} >
          <View style={styles.containerIcon}>  <img src={Instagram} width={30} /></View>
        </TouchableOpacity>

        <TouchableOpacity onPress={() => Linking.openURL('https://www.youtube.com/@bestquestrealestate')} >
          <View style={styles.containerIcon}>  <img src={YouTube} width={30} /></View>
        </TouchableOpacity>

      </View>









    </ScrollView >

  )
};

export default Menu;

const styles = StyleSheet.create({

  menuContainer: {
    //paddingVertical:5,
    //marginVertical:'.5rem',
    width: '12rem',
    alignSelf: 'center',
    alignItems: 'center',
    borderBottomColor: colours.black,
    borderBottomWidth: .5

  },



  closeContainer: {
    backgroundColor: colours.primary,
    borderRadius: 5,
    marginTop: 20,
    paddingHorizontal: 20,
    paddingVertical: 20,
    alignSelf: 'center',
  },

  closeText: {
    fontSize: '1.7rem',
    fontWeight: '200',
    color: colours.secondary,
    fontFamily: fonts.primary,

  },



  menuText: {
    marginVertical: '.6rem',
    //marginHorizontal: 20,
    color: colours.secondaryDark,
    fontSize: '1.2rem',
    fontWeight: '300',
    fontFamily: fonts.primary,

  },
  menuTextFollow: {
    marginTop: '1rem',
    marginBottom: '.6rem',
    color: colours.secondaryDark,
    fontSize: '0.9rem',
    fontWeight: '300',
    fontFamily: fonts.primary,
    alignSelf: 'center'
  },
  menuTextCS: {
    marginVertical: '.6rem',
    //marginHorizontal: 20,
    color: colours.primaryLight,
    fontSize: '1.2rem',
    fontWeight: '300',
    fontFamily: fonts.primary,

  },
  headerItemLeft:
  {
    //flex: 2,
    //flexDirection: 'row',
    justifyContent: 'flex-end',
    // marginVertical: 5,
    // marginHorizontal: width > 1000 ? 5 : 5,
    // borderWidth: 1,
    alignSelf: 'flex-end',
    paddingHorizontal: 5,
    paddingVertical: 5,
    // borderRadius: 3,
    // borderColor: colours.primaryLight

  },
  containerLogo: {
    //paddingLeft:20,
    //alignItems: 'center',
    //marginBottom: 10,
    alignSelf: 'center',
    //backgroundColor:'blue'
  },
  containerIcon: {
    marginHorizontal: 4,
    alignItems: 'center'
  },
  containerSocial: {
    marginTop: 0,
    flexDirection: 'row',
    paddingHorizontal: 10,
    justifyContent: 'center',
  },
  containerForm: {
    flex: 1,
    //marginVertical: 15,
    marginHorizontal: 15,
    backgroundColor: colours.white,
    borderRadius: 5,
    alignSelf: width > 1275 ? 'center' : 'stretch'

},


submitButton: {
  flex:1,
  //width: '90%',
  flexDirection: 'row',
  alignSelf: 'center',
  justifyContent: 'center',
  paddingHorizontal: 20,
   paddingVertical: 5,
  // borderRadius: 5,

},

textSubmit: {
  paddingHorizontal: 20,
  alignContent: 'center',
  justifyContent: 'center',
  paddingTop: 10,
  fontSize: 20,
  color: colours.white,
  fontFamily: fonts.primary

},
textH2: {
  paddingTop: width > 1275 ? 25 : 20,
  paddingHorizontal: 20,
  paddingBottom: 5,
  color: colours.primaryDark,
  fontSize: width > 1275 ? 25 : 17,
  fontWeight: '500',
  fontFamily: fonts.primary,
  textAlign: 'center',
},
textParagraph: {
  paddingHorizontal: 20,
  paddingBottom: 15,
  color: colours.black,
  fontWeight: '300',
  fontSize: width > 1275 ? 16 : 14,
  textAlign: 'center',
  fontFamily: fonts.primary,
  lineHeight: width > 1275 ? 30 : 24,
},

});


