import React, { useState, useEffect, useContext, useRef, useLayoutEffect } from 'react';
import { Pressable, ImageBackground, FlatList, StyleSheet, Animated, ScrollView, View, Image, Text, TouchableOpacity, Dimensions, SafeAreaView, Linking, ActivityIndicator } from 'react-native';

import colours from '../../config/colours';
import Header from '../../components/header'
import Footer from '../../components/footer'
import { useNavigation } from '@react-navigation/native';
import fonts from '../../config/fonts';

import WhatsApp from '../../assets/images/social/WhatsappIcon.svg';
import LeadFormFill from '../promotions/leadFormFill';
import { AuthContext } from '../../providers/dataProvider'
import Floating from '../../components/floating';
import MapIcon from '../../assets/images/icons/mapw.svg'
import { firebase } from '../../config/firebase'



const { height, width } = Dimensions.get('window')

const Properties = ({ route }) => {


    const [dimensions, setDimensions] = React.useState({ height: window.innerHeight, width: window.innerWidth })
    const handleResize = () => { setDimensions({ width: window.innerWidth, height: window.innerHeight, }); }
    const { width, height } = dimensions;

    const navigation = useNavigation();
    // const [isLoading, setIsLoading] = useState(false);
    const [isMoreLoading, setIsMoreLoading] = useState(false);

    const [lastDoc, setLastDoc] = useState(null);

    const { propdb, propdbshuffle, isLoading, setIsLoading } = useContext(AuthContext)
    const [projects, setProjects] = useState([]);
    const [projectList, setProjectList] = useState();
    const [projectListItem, setProjectListItem] = useState();
    const [isCategory, setIsCategory] = useState(false);



    useEffect(() => {
        window.scrollTo(0, 0), getProjects()
        window.addEventListener("resize", handleResize, false)
        return () => window.removeEventListener("resize", handleResize)

        //firebase.analytics();
        // eslint-disable-next-line
    }, [width, height, projectList]);

    const [pageNumber, setPageNumber] = useState(0)
    const offset = useRef(new Animated.Value(0)).current;





    const developerPropList = [...new Set(propdb.map(a => (a.developer)))]
    const typePropList = [...new Set(propdb.map(a => (a.type)))].filter(value => value !== '' && value != undefined)
    const cityPropList = [...new Set(propdb.map(a => (a.city)))].filter(value => value !== '' && value != undefined)
    const communityPropList = [...new Set(propdb.map(a => (a.community)))].filter(value => value !== '' && value != undefined)


    const topProperties = propdb.filter(a => (a.tags).includes('Featured'))
    const recentProp = (propdb.sort((a, b) => Number(b.id) - Number(a.id))).slice(0, 14)

    const developerProp = propdb.filter(a => a.developer == route.params.propertyid)
    const communityProp = propdb.filter(a => a.community == route.params.propertyid)
    const cityProp = propdb.filter(a => a.city == route.params.propertyid)
    const typeProp = propdb.filter(a => a.type == route.params.propertyid)








    const flatList = useRef()

    const getProjects = async () => {

        setIsLoading(true);

        const pageItem = route.params != undefined ? route.params.category : 'All'
        const dataSet =
            pageItem == 'Featured' ? topProperties :
                pageItem == 'Recently' ? recentProp :
                    pageItem == 'developer' ? developerProp :
                        pageItem == 'community' ? communityProp :
                            pageItem == 'type' ? typeProp :
                                pageItem == 'city' ? cityProp :
                                    pageItem == 'Shuffle' ? propdbshuffle :
                                        propdb



        setProjects(dataSet)

        const categoryList =
            pageItem == 'developer' ? developerPropList :
                pageItem == 'community' ? communityPropList :
                    pageItem == 'type' ? typePropList :
                        pageItem == 'city' ? cityPropList :
                            null

        categoryList != null ? route.params.propertyid == undefined ? setIsCategory(true) :  setIsCategory(false) :null

        setProjectList(categoryList)
        setProjectListItem(pageItem)
        setIsLoading(false);

    }

    const getProjectsMore = async () => {

        if (lastDoc) {

            setIsMoreLoading(true);

            setTimeout(async () => {

                // const snapshot = await firebase.firestore().collection('propertiesdb')
                //     //.where('tags', 'array-contains', 'Featured')
                //     .orderBy('id', 'desc')
                //     .startAfter(lastDoc)
                //     .limit(width > 1275 ? 15 : 12)
                //     .get()

                // if (!snapshot.empty) {
                //     let newProp = projects;

                //     setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
                //     for (let i = 0; i < snapshot.docs.length; i++) {
                //         let newData = snapshot.docs[i].data()
                //         let docidnew = snapshot.docs[i].id
                //         newProp.push(Object.assign(newData, { docid: docidnew }));
                //     }
                //     setProjects(newProp);
                // } else { setLastDoc(null) }

            }, 1000);
            setIsMoreLoading(false);


        }


    }




    const Loading = () => {
        return (
            <View>
                <ActivityIndicator size='large' color={colours.secondaryLight} />
            </View>
        )
    }



    const styles = StyleSheet.create({

        containerNew: {
            //backgroundColor: '#5533a2',
            //backgroundColor: '#c724b1',
            backgroundColor: colours.primaryDark,
            alignSelf: 'center',
            paddingHorizontal: 10,
            paddingVertical: 5,
            borderRadius: 15,
            position: 'absolute',
            top: 10,
            right: -25,
        },
        containerOverlay: {
            flexDirection: 'row',
            alignSelf: 'left',
            paddingHorizontal: 10,
            paddingVertical: 5,
            position: 'absolute',
            bottom: 0,
            right: 0,
            //right: -45,
        },
        textNew: {
            color: colours.white,
            fontSize: width > 1275 ? 15 : 12,
            fontFamily: fonts.primary,
            fontWeight: '600'
        },
        textNew2: {
            color: colours.white,
            fontSize: width > 1275 ? 14 : 11,
            fontFamily: fonts.primary,
            fontWeight: '500',
        },

        containerCard: {
            flex: 1,
            borderRadius: 5,
            backgroundColor: colours.white,
            width: width > 1275 ? 250 : 200,
            height: width > 1275 ? 320 : 250,
            marginHorizontal: width > 1275 ? 15 : 3,
            marginBottom: 20,
            borderWidth: 1,
            borderColor: colours.primaryLightest,
            borderRadius: 5,
            padding: 3,
            paddingHorizontal: 5,
        },


        containerCardImage: {
            overflow: 'hidden',
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            height: width > 1275 ? 180 : 120,
        },
        textCardHeading: {
            paddingTop: width > 1275 ? 25 : 20,
            paddingHorizontal: 20,
            paddingBottom: 5,
            color: colours.primaryDark,
            fontSize: width > 1275 ? 30 : 22,
            fontWeight: '500',
            fontFamily: fonts.primary,
            textAlign: 'left',
        },

        textCardTitle: {
            // paddingBottom: 5,
            color: colours.primaryDark,
            fontSize: width > 1275 ? 20 : 16,
            fontWeight: '500',
            fontFamily: fonts.primary,
            textAlign: 'left',
        },
        textCardSubtitleBold: {
            // paddingBottom: 5,
            color: colours.secondary,
            fontSize: width > 1275 ? 18 : 14,
            fontWeight: '500',
            fontFamily: fonts.primary,
            textAlign: 'left',
        },
        textCardSubtitle: {
            paddingHorizontal: 5,
            color: colours.black,
            fontWeight: '300',
            fontSize: width > 1275 ? 16 : 12,
            letterSpacing: 0.3,
            textAlign: 'left',
            fontFamily: fonts.primary,
        },
        containerCardDetailsLineRow: {
            flexDirection: 'row',
            paddingHorizontal: 2
        },

        textHovered: {
            borderBottomWidth: 2,
            borderBottomColor: colours.brand,
            marginBottom: -2,
        },
        containerScrollView: {
            flex: 1,
            marginTop: width > 999 ? pageNumber > 0 ? 60 : 78 : pageNumber > 0 ? 40 : 65
        },
        containerRight: {
            //flex: 1,
            marginVertical: 10,
            paddingVertical: 10,
        },

        containerInsideHeaderRight: {
            borderBottomWidth: 1,
            marginBottom: 7,
            borderColor: colours.primaryLight,
            marginHorizontal: width > 1000 ? 16 : 10,
            // alignSelf:'flex-start'
            // backgroundColor: colours.blueGreyLight,
        },
        textHeader: {
            paddingHorizontal: 10,
            paddingVertical: 5,
            color: colours.secondary,
            fontSize: 20,
            fontWeight: '500',
            fontFamily: fonts.primary,
            textAlign: 'justify',
        },



        textSubmit: {
            paddingHorizontal: 20,
            alignContent: 'center',
            justifyContent: 'center',
            paddingTop: 10,
            fontSize: 20,
            color: colours.white,
            fontFamily: fonts.primary

        },


        containerBody: {
            width: width > 1080 ? 1280 : width,
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignSelf: 'center',
            // flex: 1,
        },

        containerBodyMobile: {
            // flex: 1,
            alignItems: 'flex-start'
        },
        containerBodyPrimary: {
            width: width,
            alignSelf: 'center',
            backgroundColor: colours.white

        },
        containerBodySecondary: {
            width: width,
            alignSelf: 'center',
            backgroundColor: colours.white
        },

        containerDetailsRow: {
            flex: 1,
            width: width > 1275 ? 1280 : width,
            alignSelf: 'center',
            // justifyContent:'flex-start',
            flexDirection: width > 1000 ? 'row' : 'column',
            // backgroundColor:colours.secondary
        },

        containerDetails: {
            flex: 2,
            marginVertical: 15,
            marginHorizontal: width > 1275 ? 15 : 10,
            alignSelf: 'flex-start',
            backgroundColor: colours.white,
            borderRadius: 5,
        },
        containerDetailsMobile: {
            //flex: 2,
            marginVertical: 15,
            marginHorizontal: 10,
            backgroundColor: colours.white,
            borderRadius: 5,
        },
        flatlistcontainer: {
            flexWrap: 'wrap',
            alignContent: 'center',


        },
        containerForm: {
            flex: width > 1280 ? 1 : 1,
            marginVertical: 15,
            marginHorizontal: 15,
            backgroundColor: colours.white,
            borderRadius: 5,
            alignSelf: width > 1275 ? 'flex-start' : 'stretch'
        },


        containerCardCategoryRow: {
            marginVertical: 10,
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center'

        },

        containerCardCategory: {
            marginHorizontal: width > 1275 ? 5 : 3,
            paddingHorizontal: width > 1275 ? 20 : 10,
            paddingVertical: width > 1275 ? 10 : 5,
            borderRadius: 20,
            marginVertical: 7,
            borderColor: colours.primary,
            borderWidth: 1,
            alignItems: 'center',
            alignContent: 'center'
        },
        textCardCategory: {
            color: colours.primaryDark,
            fontSize: width > 1275 ? 16 : 18,
            fontWeight: '300',
            fontFamily: fonts.primary,
            letterSpacing: 0.7
        },


        containerBulletPoints: {
            flexDirection: 'column'
        },
        containerHeaderRow: {
            flexDirection: 'row',
        },
        textH1: {
            paddingTop: width > 1275 ? 25 : 20,
            paddingHorizontal: width > 1275 ? 20 : 20,
            paddingBottom: 5,
            color: colours.secondary,
            fontSize: width > 1275 ? 30 : 22,
            fontWeight: width > 1275 ? '500' : '600',
            fontFamily: fonts.primary,
            textAlign: 'left',

        },
        textH2: {
            paddingTop: width > 1275 ? 25 : 20,
            paddingHorizontal: 20,
            paddingBottom: 5,
            color: colours.primaryDark,
            fontSize: width > 1275 ? 25 : 17,
            fontWeight: '500',
            fontFamily: fonts.primary,
            textAlign: 'left',
        },
        textH3: {
            marginVertical: 0,
            // paddingTop: width > 1275 ? 20 : 10,
            paddingHorizontal: 20,
            paddingBottom: 5,
            color: colours.white,
            fontSize: width > 1275 ? 18 : 13,
            fontWeight: '500',
            fontFamily: fonts.primary,
        },
        textParagraph: {
            paddingHorizontal: 20,
            paddingBottom: 15,
            color: colours.black,
            fontWeight: '300',
            fontSize: width > 1275 ? 16 : 14,
            textAlign: 'left',
            fontFamily: fonts.primary,
            lineHeight: width > 1275 ? 30 : 24,
        },
        textParagraphBold: {
            paddingHorizontal: 20,
            paddingBottom: 15,
            color: colours.black,
            fontWeight: '500',
            fontSize: width > 1275 ? 16 : 14,
            textAlign: 'left',
            fontFamily: fonts.primary,
            lineHeight: width > 1275 ? 30 : 24,
        },
        loadMoreButton: {
            marginVertical: 10,
            //width: '90%',
            flexDirection: 'row',
            backgroundColor: colours.secondaryLight,
            alignSelf: 'center',
            justifyContent: 'center',
            paddingHorizontal: 20,
            paddingVertical: 5,
            borderRadius: 5,
        },

        submitButton: {
            width: '90%',
            flexDirection: 'row',
            backgroundColor: '#25D366',
            alignSelf: 'center',
            justifyContent: 'center',
            paddingHorizontal: 20,
            paddingVertical: 5,
            borderRadius: 5,
        },

    });




    const renderProperties = (item, index) => {
        const gradientHeight = 70;
        const gradientBackground = colours.black;
        const data = Array.from({ length: gradientHeight });


        const images = item.images[0] == undefined ? ['a', 'b'] : item.images[0].split(',')
        const imageThumb = images[1]
        const imageMain = images[0]
        return (
            <TouchableOpacity onPress={() => navigation.navigate('Property', { propertyid: item })}>


                <View style={styles.containerCard}>

                    <ImageBackground style={styles.containerCardImage} source={imageThumb} />

                    <Text numberOfLines={1} style={styles.textCardTitle}>{item.name}</Text>

                    {item.priceStart != null && item.priceStart != undefined && item.priceStart != '' &&
                        <Text style={styles.textCardSubtitleBold}>{'AED ' + item.priceStart}</Text>
                    }

                    {(item.community != null && item.community != undefined && item.community != '') &&
                        <View style={styles.containerCardDetailsLineRow}>
                            <Image source={MapIcon} style={{ width: 15, height: 20, resizeMode: 'contain' }} />
                            <Text style={styles.textCardSubtitle}>{item.community}</Text>
                        </View>
                    }

                    {/* <View style={{ justifyContent: 'flex-end' }}>
                            {item.sticker != null && item.sticker != undefined && item.sticker != '' &&
                                <Text style={styles.TextCardDetailsSticker}>{item.sticker}</Text>
                            }
                        </View> */}
                </View>
            </TouchableOpacity>
        )
    }


    function capitlizeText(word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }
    const pageItem = route.params !== undefined ? 
    route.params.category == 'Shuffle' ? ' \u276F  Properties for you' : 
    route.params.category == 'Featured' ? ' \u276F  Top Properties' : 
    route.params.category == 'Recently' ? ' \u276F  Recently Added' : 
    ('\u276F  ' + capitlizeText(route.params.category)) : ''




    const pageSubItem = route.params.propertyid !== undefined ? ('  \u276F  ' + route.params.propertyid) : ''


    return (
        <View style={{ height: height, backgroundColor: colours.white }}>
            {/* HEADER SECTION */}
            <Header animatedValue={offset} pageNumber={pageNumber} />
            <Floating animatedValue={offset} pageNumber={pageNumber} />

            <ScrollView
                style={styles.containerScrollView}
                contentContainerStyle={{ flexGrow: 1 }}
                //pagingEnabled={true}
                showsVerticalScrollIndicator={false}
                scrollEventThrottle={16}
                onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: offset } } }], { useNativeDriver: false, listener: event => { const offsetY = event.nativeEvent.contentOffset.y; setPageNumber(offsetY / height) } })}
            >
                {/* BANNER SECTION */}
                <View style={width > 999 ? styles.containerBody : styles.containerBodyMobile}>

                    <Text style={[styles.textH1, { color: colours.primary, fontSize: width > 1275 ? 30 : 25, textAlign: 'left' }]}>
                        {'PROPERTIES'}

                    </Text>


                </View>
                <View style={width > 999 ? styles.containerBody : styles.containerBodyMobile}>

                    <Text style={[styles.textH1, { paddingLeft: 10, color: colours.primary, fontSize: width > 1275 ? 20 : 15, textAlign: 'left' }]}>
                        {'Properties '}{pageItem}{pageSubItem}
                    </Text>
                </View>


                <View style={styles.containerDetailsRow}>


                    <View style={width > 1275 ? styles.containerDetails : styles.containerDetailsMobile}>


                        <View style={{ marginVertical: width > 1275 ? 0 : 5 }} />





                        {(projectList != null && isCategory) &&

                            <ScrollView contentContainerStyle={styles.containerCardCategoryRow} horizontal={true} showsHorizontalScrollIndicator={false} >
                                {projectList.map((item, index) => {
                                    return (
                                        <TouchableOpacity onPress={() => (console.log(' Category ', projectListItem, 'item', item), navigation.navigate('Properties', { category: projectListItem, propertyid: item }), setIsCategory(false))}>
                                            <View style={styles.containerCardCategory}>
                                                <Text numberOfLines={1} style={styles.textCardCategory}>{item}</Text>
                                            </View>
                                        </TouchableOpacity>
                                    )
                                })}
                            </ScrollView>

                        }





                        {/* FEATURED*/}
                        {/* <View style={styles.containerHeaderRow}>
                                    <Text style={styles.textH1}>{'Recently Added '}</Text>
                                </View> */}

                        {(width > 1275 && !isCategory) &&
                            <FlatList
                                numColumns={3}
                                showsHorizontalScrollIndicator={false}
                                data={projects}
                                keyExtractor={(item, index) => index.toString()}
                                renderItem={({ item, index }) => renderProperties(item, index)}
                                initialNumToRender={12}
                                ref={flatList}
                                contentContainerStyle={styles.flatlistcontainer}
                            />}

                        {(width < 1275 && !isCategory) &&
                            <FlatList
                                numColumns={2}
                                showsHorizontalScrollIndicator={false}
                                data={projects}
                                keyExtractor={(item, index) => index.toString()}
                                renderItem={({ item, index }) => renderProperties(item, index)}
                                initialNumToRender={12}
                                ref={flatList}
                                contentContainerStyle={styles.flatlistcontainer}
                            />
                        }
                        {/* {!isMoreLoading && lastDoc &&
                            <View >
                                <TouchableOpacity
                                    style={styles.loadMoreButton}
                                    onPress={() => getProjectsMore()}>
                                    <Text style={styles.textH3}>{'Load More Properties ...'}</Text>
                                </TouchableOpacity>
                            </View>
                        } */}
                        {isMoreLoading &&
                            <Loading />
                        }






                    </View>






                    <View style={styles.containerForm}>


                        <Text style={styles.textH2}>
                            {"Let's Start"}
                        </Text>
                        {/* <Text style={[styles.textParagraph, { color: colours.secondaryDarker }]}>
                                {'Free consultation'}
                            </Text> */}
                        <LeadFormFill pageName={'About'} />

                        {/* <View style={{ marginVertical: 10, borderBottomColor: colours.blueGreyLight, borderBottomWidth: 15 }} /> */}

                        {/* <Text style={styles.textH2}>
                                {'Chat With Us'}
                            </Text>
                            <Text style={[styles.textParagraph, { color: colours.secondaryDarker }]}>
                                {'Start Converstation in WhatsApp'}
                            </Text>



                            <TouchableOpacity style={styles.submitButton} onPress={() => Linking.openURL('https://wa.me/971507893788')} >
                                <View> <Image source={WhatsApp} style={{ width: 40, height: 40 }} /></View>
                                <View><Text style={styles.textSubmit}>{'Start Chat'}</Text>  </View>
                            </TouchableOpacity> */}

                        <View style={{ marginVertical: 10 }} />

                    </View>
                </View>














                {/* FOOTER SECTION */}
                <Footer animatedValue={offset} pageNumber={pageNumber} />
            </ScrollView>
        </View>

    );
};




export default Properties;




