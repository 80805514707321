
import React from 'react';



import { NavigationContainer, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';


import Home from './home/home' 
import About from './about/about';
import Contact from './contact/contact';
import Services from './services/services';
import Property from './properties/property';
import Properties from './properties/properties';




const HomeStack = createNativeStackNavigator();
function HomeStackScreen({route, navigation}) {


  return (
    <HomeStack.Navigator screenOptions={{ headerShown: false}}>
      <HomeStack.Screen name='Home' component={Home} options={{headerShown: false,title:'BEST QUEST REAL ESTATE'}}/>
      <HomeStack.Screen name='About' component={About} options={{headerShown: false}}/>
      <HomeStack.Screen name='Contact' component={Contact} options={{headerShown: false}}/>
      <HomeStack.Screen name='Properties' component={Properties} options={{headerShown: false}}/>
      <HomeStack.Screen name='Property' component={Property} options={{headerShown: false}}/>
      <HomeStack.Screen name='Services' component={Services} options={{headerShown: false}}/>

    </HomeStack.Navigator>
  );
}

const linking = {
  prefixes: ['https://bquae.com', 'bquae://','localhost:3000'],
  config: {
    screens: {
      Home: '',
      About: 'about',
      Contact: 'contact',
      Properties: 'properties',
      Property: 'property',
      Services: 'services',


    }
  },
};


function HomeNavigator() {
  
  return (
    
    <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>} theme={DarkTheme}>
      <HomeStackScreen />
    </NavigationContainer>
    
  )
}

export default HomeNavigator;
