//import * as firebase from 'firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDPdS55Jlk6Y-y7xScZC16sWT_jSJo5wow",
  authDomain: "bestquest-website.firebaseapp.com",
  projectId: "bestquest-website",
  storageBucket: "bestquest-website.appspot.com",
  messagingSenderId: "214785817895",
  appId: "1:214785817895:web:03cf91308750873d91fd02",
  measurementId: "G-ZGTZ7QHEHG"
};

const firebaseConfig2 = {
  apiKey: "AIzaSyAL0eIOAYlLqSeeZl60TeTDkOsA-AreDfQ",
  authDomain: "aqaar-estate.firebaseapp.com",
  projectId: "aqaar-estate",
  storageBucket: "aqaar-estate.appspot.com",
  messagingSenderId: "445047256705",
  appId: "1:445047256705:web:67cadab4523856a3e93f80",
  measurementId: "G-5V9VCZLPSK"
};


// if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  var leadfirebase = firebase.initializeApp(firebaseConfig2,'aqaar-estate');

// }

export { firebase,leadfirebase };