import React, { useState, useEffect, useContext, useRef, useLayoutEffect } from 'react';
import { ActivityIndicator, ImageBackground, FlatList, StyleSheet, Animated, ScrollView, View, Image, Text, TouchableOpacity, Dimensions, SafeAreaView, Linking, ImageBackgroundBase } from 'react-native';


import colours from '../../config/colours';
import fonts from '../../config/fonts'

import Header from '../../components/header'
import Footer from '../../components/footer'
import { useNavigation } from '@react-navigation/native';

import HomeBanner from '../../components/homeBanner';
import MapIcon from '../../assets/images/icons/mapw.svg'
import iconLeftCircle from '../../assets/icons/left-circle.svg'
import iconRightCircle from '../../assets/icons/right-circle.svg'

import iconVilla from '../../assets/images/villa.png'
import iconTH from '../../assets/images/townhouse.png'
import iconAPT from '../../assets/images/apartment.png'

import { AuthContext } from '../../providers/dataProvider'

const { height, width } = Dimensions.get('window')

import 'firebase/analytics'
import Floating from '../../components/floating';

const Home = () => {

  const { propdb, propdbshuffle, isLoading } = useContext(AuthContext)

  const [dimensions, setDimensions] = React.useState({ height: window.innerHeight, width: window.innerWidth })
  const handleResize = () => { setDimensions({ width: window.innerWidth, height: window.innerHeight, }); }

  let CurrentSlide = 0;
  const scrollRef = useRef()

  //banner things

  const goToNextPage = () => {
    if (CurrentSlide >= (topProperties.length - 4))
      CurrentSlide = -1;
    scrollRef.current.scrollToIndex({
      index: ++CurrentSlide,
      x: 250 * CurrentSlide,
      y: 0,
      animated: true,
    });
  };
  const goToPrevPage = () => {
    if (CurrentSlide <= (0))
      CurrentSlide = topProperties.length - 4;
    scrollRef.current.scrollToIndex({
      index: --CurrentSlide,
      x: 250 * CurrentSlide,
      y: 0,
      animated: true,
    });
  };
  //banner things ends


  const { width, height } = dimensions;

  useEffect(() => {
    // let timerId = setInterval(goToNextPage, intervalTime);
    // return () => clearInterval(timerId),
    window.scrollTo(0, 0),
      window.addEventListener("resize", handleResize, false)
    return () => window.removeEventListener("resize", handleResize)

    //firebase.analytics();
    // eslint-disable-next-line
  }, [width, height]);

  const navigation = useNavigation();
  const [pageNumber, setPageNumber] = useState(0)
  const offset = useRef(new Animated.Value(0)).current;




  const topProperties = propdb.filter(a => (a.tags).includes('Featured'))
  const recentProp = (propdb.sort((a, b) => Number(b.id) - Number(a.id))).slice(0, 14)

  const developerProp = [...new Set(propdb.map(a => (a.developer)))]
  const communityProp = [...new Set(propdb.map(a => (a.community)))].filter(value => value !== '' && value != undefined)
  const typeProp = [...new Set(propdb.map(a => (a.type)))].filter(value => value !== '' && value != undefined)
  const cityProp = [...new Set(propdb.map(a => (a.city)))].filter(value => value !== '' && value != undefined)

  const shuffledProp = propdbshuffle





  const iconSize = width > 1080 ? 50 : 40


  const Loading = () => {
    return (
      <View>
        <ActivityIndicator size='large' color={colours.secondaryLight} />
      </View>
    )
  }


  // STYLE SECTION
  const styles = StyleSheet.create({

    container: {
      flexGrow: 1,
    },
    containerBody: {
      width: width > 1080 ? 1280 : width,
      //flexDirection: 'row',
      // flexWrap: 'wrap',
      alignSelf: 'center'
    },

    containerBodyMobile: {
      flex: 1,
      alignItems: 'center'
    },
    containerBodyPrimary: {
      backgroundColor: colours.white
    },
    containerBodySecondary: {
      alignSelf: 'center',
      backgroundColor: colours.white
    },

    flatlistcontainer: {
      width: width > 1080 ? 1280 : width,
      paddingVertical: 5,
      paddingHorizontal: 3
    },
    containerNew: {
      backgroundColor: colours.primary,
      alignSelf: 'center',
      paddingHorizontal: 10,
      paddingVertical: 5,
      borderRadius: 15,
      position: 'absolute',
      top: 15,
      right: -25,
    },
    containerOverlay: {
      flexDirection: 'row',
      alignSelf: 'left',
      paddingHorizontal: 5,
      paddingVertical: 5,
      position: 'absolute',
      bottom: 0,
      right: 0,
      //right: -45,
    },
    textNew: {
      color: colours.white,
      fontSize: width > 1275 ? 15 : 12,
      fontFamily: fonts.primary,
      fontWeight: '600'
    },
    textNew2: {
      color: colours.white,
      fontSize: width > 1275 ? 14 : 11,
      fontFamily: fonts.primary,
      fontWeight: '500',
    },
    textSeeMoreButton: {
      color: colours.white,
      fontSize: width > 1275 ? 20 : 16,
      fontFamily: fonts.primary,
      fontWeight: '400',
      backgroundColor: colours.primaryDark,
      textAlign: 'right',
      paddingHorizontal: 20,
      paddingVertical: 5,
      borderRadius: 3,
    },

    containerCard: {
      flex: 1,
      borderRadius: 5,
      backgroundColor: colours.white,
      width: width > 1275 ? 250 : 200,
      height: width > 1275 ? 320 : 250,
      marginHorizontal: width > 1275 ? 15 : 5,
      marginBottom: 0,
      borderWidth: 1,
      borderColor: colours.primaryLightest,
      borderRadius: 5,
      padding: 3
    },

    containerCardImage: {
      overflow: 'hidden',
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      height: width > 1275 ? 230 : 160,
    },
    containerCardDetailsLineRow: {
      flexDirection: 'row',
      paddingHorizontal: 0
    },

    TextCardDetailsSticker: {
      alignSelf: 'flex-start',
      marginTop: 5,
      color: colours.white,
      fontSize: width > 1080 ? 14 : 12,
      fontFamily: fonts.primary,
      fontWeight: '300',
      letterSpacing: 0.3,
      backgroundColor: colours.secondaryLight,
      paddingVertical: 3,
      paddingHorizontal: 7,
      borderRadius: 3,
    },

    textCardHeading: {
      paddingTop: width > 1275 ? 25 : 20,
      paddingHorizontal: 20,
      paddingBottom: 5,
      color: colours.primaryDark,
      fontSize: width > 1275 ? 30 : 22,
      fontWeight: '500',
      fontFamily: fonts.primary,
      textAlign: 'left',
    },

    textCardTitle: {
      // paddingBottom: 5,
      color: colours.primaryDark,
      fontSize: width > 1275 ? 20 : 16,
      fontWeight: '500',
      fontFamily: fonts.primary,
      textAlign: 'left',
    },
    textH2: {
      color: colours.primaryDark,
      fontSize: width > 1275 ? 24 : 16,
      fontWeight: '300',
      fontFamily: fonts.primary,
      letterSpacing: 0.7
    },
    textCardSubtitleBold: {
      // paddingBottom: 5,
      color: colours.secondary,
      fontSize: width > 1275 ? 18 : 14,
      fontWeight: '500',
      fontFamily: fonts.primary,
      textAlign: 'left',
    },
    textCardSubtitle: {
      paddingHorizontal: 5,
      color: colours.black,
      fontWeight: '300',
      fontSize: width > 1275 ? 16 : 12,
      letterSpacing: 0.3,
      textAlign: 'left',
      fontFamily: fonts.primary,
    },
    textHovered: {
      borderBottomWidth: 2,
      borderBottomColor: colours.primary,
      marginBottom: -2,
    },
    //CATEGORY//

    containerFlatlistCategory: {
      width: width > 1080 ? 1280 : width,
      paddingVertical: 30,
      paddingHorizontal: 20
    },

    containerCardCategoryRow: {
      paddingHorizontal: width > 1275 ? 7 : 5,
      marginVertical: 10,
      //flex:1,
      flexDirection: 'row',
      justifyContent: 'center'
    },

    containerCardCategory: {
      marginHorizontal: width > 1275 ? 5 : 3,
      paddingHorizontal: width > 1275 ? 20 : 10,
      paddingVertical: width > 1275 ? 20 : 10,
      borderRadius: 50,
      //marginVertical:5,
      borderColor: colours.primary,
      borderWidth: 1,
      alignItems: 'center'
    },

    containerCardImageCategory: {
      height: width > 1275 ? 150 : 100,
      width: width > 1275 ? 150 : 100,
      alignSelf: 'center'

    },
    textCardHeadingCategory: {
      paddingTop: width > 1275 ? 45 : 35,
      paddingHorizontal: 20,
      color: colours.secondary,
      fontSize: width > 1275 ? 30 : 26,
      fontWeight: '500',
      fontFamily: fonts.primary,
      textAlign: 'center',

    },

    textCardTitleCategory: {
      paddingTop: 5,
      paddingHorizontal: 20,
      paddingBottom: 5,
      color: colours.secondaryDarkest,
      fontSize: width > 1275 ? 17 : 14,
      fontWeight: '500',
      fontFamily: fonts.primary,
      textAlign: 'center',
    },
    textCardSubtitleCategory: {
      paddingTop: 5,
      paddingHorizontal: 20,
      paddingBottom: 15,
      color: colours.black,
      fontWeight: '300',
      fontSize: width > 1275 ? 14 : 10,
      textAlign: 'left',
      fontFamily: fonts.primary,

    },
    textHoveredCategory: {
      borderBottomWidth: 2,
      borderBottomColor: colours.brand,
      marginBottom: -2,
    },
    containerHeaderRow: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: colours.white
    }
  });

  {/* MAIN SECTION*/ }

  return (
    <View style={{ height: height, backgroundColor: colours.white, marginTop: 0 }}>
      {/* HEADER SECTION */}
      <Header animatedValue={offset} pageNumber={pageNumber} pageData={'home'} />
      <Floating />

      <ScrollView
        //style={{ flex:1 }}
        contentContainerStyle={{ flexGrow: 1 }}
        showsVerticalScrollIndicator={false}
        scrollEventThrottle={16}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: offset } } }],
          {
            useNativeDriver: false,
            listener: event => {
              const offsetY = event.nativeEvent.contentOffset.y
              setPageNumber(offsetY / height)
            }
          }
        )}
      >
        {/* BANNER SECTION */}

        <HomeBanner />


        {/* FEATURED SECTION*/}


        <View style={styles.containerBody}>


          <View style={styles.containerHeaderRow}>
            <Text style={styles.textCardHeading}>{'Top Properties'}</Text>

            <View style={{ flexDirection: 'row', alignItems: 'flex-end', paddingRight: 5 }}>

              <View style={[styles.containerHeaderRow, { justifyContent: 'flex-end', paddingRight: 5 }]}>
                <TouchableOpacity style={{ justifyContent: 'center' }}
                  onPress={() => navigation.navigate('Properties', { category: 'Featured' })}
                >
                  <Text style={styles.textSeeMoreButton}>{'See  All'}</Text>
                </TouchableOpacity>
              </View>

            </View>

          </View>


          {isLoading && <Loading />}

          <ScrollView ref={scrollRef} contentContainerStyle={styles.containerCardCategoryRow} horizontal={true} showsHorizontalScrollIndicator={false} >

            {topProperties.map((item, index) => {
              const images = item.images[0] == undefined ? ['a', 'b'] : item.images[0].split(',')
              const imageThumb = images[1]
              return (
                <TouchableOpacity index={index} onPress={() => navigation.navigate('Property', { propertyid: item })}>
                  <View style={styles.containerCard}>
                    <ImageBackground style={styles.containerCardImage} source={imageThumb} />
                    <Text numberOfLines={1} style={styles.textCardTitle}>{item.name}</Text>
                    {item.priceStart != null && item.priceStart != undefined && item.priceStart != '' &&
                      <Text style={styles.textCardSubtitleBold}>{'AED ' + item.priceStart}</Text>
                    }
                    {item.community != null && item.community != undefined && item.community != '' &&
                      <View style={styles.containerCardDetailsLineRow}>
                        <Image source={MapIcon} style={{ width: 15, height: 20, resizeMode: 'contain' }} />
                        <Text style={styles.textCardSubtitle}>{item.community}</Text>
                      </View>
                    }
                    <View style={{ justifyContent: 'flex-end' }}>
                      {item.sticker != null && item.sticker != undefined && item.sticker != '' &&
                        <Text style={styles.TextCardDetailsSticker}>{item.sticker}</Text>
                      }
                    </View>
                  </View>
                </TouchableOpacity>
              )
            })
            }
          </ScrollView>


        </View>



        {/* RECENTLY ADDED SECTION*/}


        <View style={styles.containerBody}>


          <View style={styles.containerHeaderRow}>
            <Text style={styles.textCardHeading}>{'Recently Added '}</Text>

            <View style={{ flexDirection: 'row', alignItems: 'flex-end', paddingRight: 5 }}>
              {/* <TouchableOpacity onPress={() => goToPrevPage()}>
                <Image source={iconLeftCircle} style={{ width: iconSize, height: iconSize, padding: 5 }} />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => goToNextPage()}>
                <Image source={iconRightCircle} style={{ width: iconSize, height: iconSize, padding: 5 }} />
              </TouchableOpacity> */}
              <View style={[styles.containerHeaderRow, { justifyContent: 'flex-end', paddingRight: 5 }]}>
                <TouchableOpacity style={{ justifyContent: 'center' }}
                  onPress={() => navigation.navigate('Properties',  { category: 'Recently' })}
                >
                  <Text style={styles.textSeeMoreButton}>{'See  All'}</Text>
                </TouchableOpacity>
              </View>

            </View>

          </View>


          <ScrollView contentContainerStyle={styles.containerCardCategoryRow} horizontal={true} showsHorizontalScrollIndicator={false} >

            {recentProp.map((item, index) => {
              const images = item.images[0] == undefined ? ['a', 'b'] : item.images[0].split(',')
              const imageThumb = images[1]
              return (
                <TouchableOpacity onPress={() => navigation.navigate('Property', { propertyid: item })}>
                  <View style={styles.containerCard}>
                    <ImageBackground style={styles.containerCardImage} source={imageThumb} />
                    <Text numberOfLines={1} style={styles.textCardTitle}>{item.name}</Text>
                    {item.priceStart != null && item.priceStart != undefined && item.priceStart != '' &&
                      <Text style={styles.textCardSubtitleBold}>{'AED ' + item.priceStart}</Text>
                    }
                    {item.community != null && item.community != undefined && item.community != '' &&
                      <View style={styles.containerCardDetailsLineRow}>
                        <Image source={MapIcon} style={{ width: 15, height: 20, resizeMode: 'contain' }} />
                        <Text style={styles.textCardSubtitle}>{item.community}</Text>
                      </View>
                    }
                    <View style={{ justifyContent: 'flex-end' }}>
                      {item.sticker != null && item.sticker != undefined && item.sticker != '' &&
                        <Text style={styles.TextCardDetailsSticker}>{item.sticker}</Text>
                      }
                    </View>
                  </View>
                </TouchableOpacity>
              )
            })
            }
          </ScrollView>

          <View style={[styles.containerHeaderRow, { justifyContent: 'flex-end', paddingRight: 5 }]}>
            {/* <TouchableOpacity style={{ justifyContent: 'center' }}
              onPress={() => navigation.navigate('Properties', { category: 'Recently' })}
            >
              <Text style={styles.textSeeMoreButton}>{'See  More'}</Text>
            </TouchableOpacity> */}

          </View>

        </View>






        {/* DEVELOPER SECTION*/}

        <View style={{ marginVertical: width > 999 ? 30 : 15 }} />

        <View style={styles.containerBody}>


          <View style={styles.containerHeaderRow}>
            <Text style={styles.textCardHeading}>{'Developers'}</Text>

            <View style={{ flexDirection: 'row', alignItems: 'flex-end', paddingRight: 5 }}>
            <View style={[styles.containerHeaderRow, { justifyContent: 'flex-end', paddingRight: 5 }]}>
                <TouchableOpacity style={{ justifyContent: 'center' }}
                  onPress={() => navigation.navigate('Properties', { category: 'developer' })}
                >
                  <Text style={styles.textSeeMoreButton}>{'See  All'}</Text>
                </TouchableOpacity>
              </View>

            </View>

          </View>


          <ScrollView contentContainerStyle={styles.containerCardCategoryRow} horizontal={true} showsHorizontalScrollIndicator={false} >

            {developerProp.map((item, index) => {
              return (
                <TouchableOpacity onPress={() => navigation.navigate('Properties', { category: 'developer', propertyid: item })}>
                  <View style={styles.containerCardCategory}>
                    {/* <ImageBackground style={styles.containerCardImage} source={imageThumb} /> */}
                    <Text numberOfLines={1} style={styles.textH2}>{item}</Text>

                  </View>
                </TouchableOpacity>
              )
            })
            }
          </ScrollView>


          <View style={[styles.containerHeaderRow, { justifyContent: 'flex-end', paddingRight: 5 }]}>
            {/* <TouchableOpacity style={{ justifyContent: 'center' }}
              onPress={() => navigation.navigate('Properties', { category: 'developer' })}
            >
              <Text style={styles.textSeeMoreButton}>{'See  More'}</Text>
            </TouchableOpacity> */}
          </View>




        </View>








        {/* COMMUNITY SECTION*/}

        <View style={{ marginVertical: width > 999 ? 30 : 15 }} />

        <View style={styles.containerBody}>


          <View style={styles.containerHeaderRow}>
            <Text style={styles.textCardHeading}>{'Communities'}</Text>

            <View style={{ flexDirection: 'row', alignItems: 'flex-end', paddingRight: 5 }}>
              {/* <TouchableOpacity onPress={() => goToPrevPage()}>
                <Image source={iconLeftCircle} style={{ width: iconSize, height: iconSize, padding: 5 }} />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => goToNextPage()}>
                <Image source={iconRightCircle} style={{ width: iconSize, height: iconSize, padding: 5 }} />
              </TouchableOpacity> */}
              <View style={[styles.containerHeaderRow, { justifyContent: 'flex-end', paddingRight: 5 }]}>
                <TouchableOpacity style={{ justifyContent: 'center' }}
                  onPress={() => navigation.navigate('Properties', { category: 'community' })}
                >
                  <Text style={styles.textSeeMoreButton}>{'See  All'}</Text>
                </TouchableOpacity>
              </View>

            </View>

          </View>


          <ScrollView contentContainerStyle={styles.containerCardCategoryRow} horizontal={true} showsHorizontalScrollIndicator={false} >

            {communityProp.map((item, index) => {
              return (
                <TouchableOpacity onPress={() => navigation.navigate('Properties', { category: 'community', propertyid: item })}>
                  <View style={styles.containerCardCategory}>
                    {/* <ImageBackground style={styles.containerCardImage} source={imageThumb} /> */}
                    <Text numberOfLines={1} style={styles.textH2}>{item}</Text>

                  </View>
                </TouchableOpacity>
              )
            })
            }
          </ScrollView>


          <View style={[styles.containerHeaderRow, { justifyContent: 'flex-end', paddingRight: 5 }]}>
            {/* <TouchableOpacity style={{ justifyContent: 'center' }}
              onPress={() => navigation.navigate('Properties', { category: 'community' })}
            >
              <Text style={styles.textSeeMoreButton}>{'See  More'}</Text>
            </TouchableOpacity> */}
          </View>




        </View>


        {/* TYPE SECTION*/}

        <View style={{ marginVertical: width > 999 ? 30 : 15 }} />

        <View style={styles.containerBody}>


          <View style={styles.containerHeaderRow}>
            <Text style={styles.textCardHeading}>{'Property Types'}</Text>

            <View style={{ flexDirection: 'row', alignItems: 'flex-end', paddingRight: 5 }}>
            <View style={[styles.containerHeaderRow, { justifyContent: 'flex-end', paddingRight: 5 }]}>
                <TouchableOpacity style={{ justifyContent: 'center' }}
                  onPress={() => navigation.navigate('Properties', { category: 'type' })}
                >
                  <Text style={styles.textSeeMoreButton}>{'See  All'}</Text>
                </TouchableOpacity>
              </View>

            </View>

          </View>


          <ScrollView contentContainerStyle={styles.containerCardCategoryRow} horizontal={true} showsHorizontalScrollIndicator={false} >

            {typeProp.map((item, index) => {
              return (
                <TouchableOpacity onPress={() => navigation.navigate('Properties', { category: 'type', propertyid: item })}>
                  <View style={styles.containerCardCategory}>
                    {/* <ImageBackground style={styles.containerCardImage} source={imageThumb} /> */}
                    <Text numberOfLines={1} style={styles.textH2}>{item}</Text>

                  </View>
                </TouchableOpacity>
              )
            })
            }
          </ScrollView>


          <View style={[styles.containerHeaderRow, { justifyContent: 'flex-end', paddingRight: 5 }]}>
            {/* <TouchableOpacity style={{ justifyContent: 'center' }}
              onPress={() => navigation.navigate('Properties', { category: 'type' })}
            >
              <Text style={styles.textSeeMoreButton}>{'See  More'}</Text>
            </TouchableOpacity> */}
          </View>




        </View>



        {/* CITIES SECTION*/}

        <View style={{ marginVertical: width > 999 ? 30 : 15 }} />

        <View style={styles.containerBody}>


          <View style={styles.containerHeaderRow}>
            <Text style={styles.textCardHeading}>{'Cities'}</Text>

            <View style={{ flexDirection: 'row', alignItems: 'flex-end', paddingRight: 5 }}>
            <TouchableOpacity style={{ justifyContent: 'center' }}
                  onPress={() => navigation.navigate('Properties', { category: 'city' })}
                >
                  <Text style={styles.textSeeMoreButton}>{'See  All'}</Text>
                </TouchableOpacity>
            </View>

          </View>


          <ScrollView contentContainerStyle={styles.containerCardCategoryRow} horizontal={true} showsHorizontalScrollIndicator={false} >

            {cityProp.map((item, index) => {
              return (
                <TouchableOpacity onPress={() => navigation.navigate('Properties', { category: 'city', propertyid: item })}>
                  <View style={styles.containerCardCategory}>
                    {/* <ImageBackground style={styles.containerCardImage} source={imageThumb} /> */}
                    <Text numberOfLines={1} style={styles.textH2}>{item}</Text>

                  </View>
                </TouchableOpacity>
              )
            })
            }
          </ScrollView>


          <View style={[styles.containerHeaderRow, { justifyContent: 'flex-end', paddingRight: 5 }]}>
            {/* <TouchableOpacity style={{ justifyContent: 'center' }}
              onPress={() => navigation.navigate('Properties', { category: 'city' })}
            >
              <Text style={styles.textSeeMoreButton}>{'See  More'}</Text>
            </TouchableOpacity> */}
          </View>




        </View>







        {/* SHUFFLED SECTION*/}


        <View style={styles.containerBody}>


          <View style={styles.containerHeaderRow}>
            <Text style={styles.textCardHeading}>{'Properties for you'}</Text>

            <View style={{ flexDirection: 'row', alignItems: 'flex-end', paddingRight: 5 }}>
            <View style={[styles.containerHeaderRow, { justifyContent: 'flex-end', paddingRight: 5 }]}>
                <TouchableOpacity style={{ justifyContent: 'center' }}
                  onPress={() => navigation.navigate('Properties', { category: 'Shuffle' })}
                >
                  <Text style={styles.textSeeMoreButton}>{'See  All'}</Text>
                </TouchableOpacity>
              </View>

            </View>

          </View>


          <ScrollView contentContainerStyle={styles.containerCardCategoryRow} horizontal={true} showsHorizontalScrollIndicator={false} >

            {shuffledProp.map((item, index) => {
              const images = item.images[0] == undefined ? ['a', 'b'] : item.images[0].split(',')
              const imageThumb = images[1]
              return (
                <TouchableOpacity onPress={() => navigation.navigate('Property', { category: 'Shuffle' })}>
                  <View style={styles.containerCard}>
                    <ImageBackground style={styles.containerCardImage} source={imageThumb} />
                    <Text numberOfLines={1} style={styles.textCardTitle}>{item.name}</Text>
                    {item.priceStart != null && item.priceStart != undefined && item.priceStart != '' &&
                      <Text style={styles.textCardSubtitleBold}>{'AED ' + item.priceStart}</Text>
                    }
                    {item.community != null && item.community != undefined && item.community != '' &&
                      <View style={styles.containerCardDetailsLineRow}>
                        <Image source={MapIcon} style={{ width: 15, height: 20, resizeMode: 'contain' }} />
                        <Text style={styles.textCardSubtitle}>{item.community}</Text>
                      </View>
                    }
                    <View style={{ justifyContent: 'flex-end' }}>
                      {item.sticker != null && item.sticker != undefined && item.sticker != '' &&
                        <Text style={styles.TextCardDetailsSticker}>{item.sticker}</Text>
                      }
                    </View>
                  </View>
                </TouchableOpacity>
              )
            })
            }
          </ScrollView>

          <View style={[styles.containerHeaderRow, { justifyContent: 'flex-end', paddingRight: 5 }]}>
            {/* <TouchableOpacity style={{ justifyContent: 'center' }}
              onPress={() => navigation.navigate('Properties', { category: 'Shuffle' })}
            >
              <Text style={styles.textSeeMoreButton}>{'See  More'}</Text>
            </TouchableOpacity> */}

          </View>

        </View>











        {/* FOOTER SECTION */}
        <Footer />
      </ScrollView>
    </View>


  );
};



export default Home;
