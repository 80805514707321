import React, { createContext, useState, useEffect } from 'react';
import { firebase } from '../config/firebase'

export const AuthContext = createContext({});

export const DataProvider = ({ children }) => {


  useEffect(() => {
    getPropdb()
    // getFeaturedProjectsData(),getProjectsDubai(),getProjectsAbuDhabi(),getProjectsRecent()
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [featuredProjects, setFeaturedProjects] = useState([]);
  const [projectsDubai, setProjectsDubai] = useState([]);
  const [projectsAbuDhabi, setProjectsAbuDhabi] = useState([]);
  const [projectsRecent, setProjectsRecent] = useState([]);
  const [propdb, setPropdb] = useState([])
  const [propdbshuffle, setPropdbshuffle] = useState([])

  const getPropdb = async () => {

    setIsLoading(true);
    const snapshot = await firebase.firestore().collection('bqpropdb')
    .where('doc', '==', 'propdb')

      // .doc('propdball')
      .limit(1)
      .get()

    if (!snapshot.empty) {
      let Projects = snapshot.docs.map((doc) => ({ docid: doc.id, ...doc.data() }))
      setPropdb(Projects[0].propdb);
      setPropdbshuffle((Projects[0].propdb).map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value))

      setIsLoading(false);
    }
  }


  const getFeaturedProjectsData = async () => {

    setIsLoading(true);
    const snapshot = await firebase.firestore().collection('propertiesdb')
      .where('tags', 'array-contains', 'Featured')
      .orderBy('id', 'desc')
      .limit(15)
      .get()

    if (!snapshot.empty) {
      let Projects = snapshot.docs.map((doc) => ({ docid: doc.id, ...doc.data() }))
      setFeaturedProjects(Projects);
      setIsLoading(false);
    }
  }

  const getProjectsDubai = async () => {

    setIsLoading(true);
    const snapshot = await firebase.firestore().collection('propertiesdb')
      .where('city', '==', 'Dubai')
      .orderBy('id', 'desc')
      .limit(4)
      .get()

    if (!snapshot.empty) {
      let Projects = snapshot.docs.map((doc) => ({ docid: doc.id, ...doc.data() }))
      setProjectsDubai(Projects);
      setIsLoading(false);
    }
  }

  const getProjectsAbuDhabi = async () => {

    setIsLoading(true);
    const snapshot = await firebase.firestore().collection('propertiesdb')
      .where('city', '==', 'Abu Dhabi')
      .orderBy('id', 'desc')
      .limit(4)
      .get()

    if (!snapshot.empty) {
      let Projects = snapshot.docs.map((doc) => ({ docid: doc.id, ...doc.data() }))
      setProjectsAbuDhabi(Projects);
      setIsLoading(false);
    }
  }


  const getProjectsRecent = async () => {

    setIsLoading(true);
    const snapshot = await firebase.firestore().collection('propertiesdb')
      .orderBy('id', 'desc')
      .limit(4)
      .get()

    if (!snapshot.empty) {
      let Projects = snapshot.docs.map((doc) => ({ docid: doc.id, ...doc.data() }))
      setProjectsRecent(Projects);
      setIsLoading(false);
    }
  }






  return (
    <AuthContext.Provider
      value={{
        propdbshuffle,propdb, featuredProjects, projectsDubai, projectsAbuDhabi, projectsRecent, 
        isLoading,setIsLoading
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};