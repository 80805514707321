import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Animated, StyleSheet, View, Pressable, Button, Image, Text, TouchableOpacity, Linking, Dimensions, FlatList, ActivityIndicator, RefreshControl, ScrollView, SafeAreaView } from 'react-native';

import colours from '../config/colours';

import Mobile from '../assets/images/social/mobileIcon.svg'

import Form from '../assets/images/social/mail.svg'
import WhatsApp from '../assets/images/social/WhatsappIcon.svg';

import Modal from 'react-native-modal';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useNavigation } from '@react-navigation/native';
import fonts from '../config/fonts'
import Menu from './menu';
// const { height, width } = Dimensions.get('window')

const Floating = ({ animatedValue, pageNumber }) => {

    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })
    const handleResize = () => {
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }



    const { width, height } = dimensions;

    useEffect(() => {
        window.scrollTo(0, 0)
        window.addEventListener("resize", handleResize, false);
        return () => window.removeEventListener("resize", handleResize);

        //firebase.analytics();
        // eslint-disable-next-line
    }, [width, height]);



    const navigation = useNavigation();
    const [isModalVisibleContact, setModalVisibleContact] = useState(false);
    const toggleModal = () => {
        setModalVisibleContact(!isModalVisibleContact);
    };

    //STYLES
    const styles = StyleSheet.create({
        container: {
            position: 'absolute',
            bottom: 0,

        },
        containerCenter: {
            alignSelf: 'center'

        },
        containerMain: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        containerLogo: {
            //paddingLeft: width > 1000 ? 20 : 5,

        },

        containerMenu: {
            flexDirection: 'row',


        },
        containerMenuMobile: {
            width: width>1275?75:55,
            alignSelf: 'flex-end',
            backgroundColor: colours.white,
            paddingVertical: 5,
            borderRadius: 20,

            backgroundColor:'#25D366'

        },
        containerMenuLeft: {
            flex: 1,
            alignItems: 'flex-start',
        },
        containerLogoM: {
            flex: 4,
            justifyContent: 'center',
            alignItems: 'center',
        },
        containerMenuRight: {
            flex: 1,
            alignItems: 'flex-end',
        },

        containerMenuText: {
            color: colours.primary,
            fontWeight: '200',
            fontSize: 16,
            marginHorizontal: 10,
            paddingVertical: 5,
            fontFamily: fonts.primary,
        },
        hovered: {
            // backgroundColor: 'rgba(255,0,0,0.2)',
            // borderRadius: 4,
            borderBottomWidth: 1,
            borderBottomColor: colours.primary,
            marginBottom: -2,


        },
        modalContainer: {
            flex: 1,
            flexDirection: 'column',
            backgroundColor: colours.white,
            justifyContent: 'flex-start',
            width: '80%',
            margin: 0,
        },
        submitButton: {
            flex: 1,
            //width: '90%',
            flexDirection: 'row',
            alignSelf: 'center',
            justifyContent: 'center',
            paddingHorizontal: 20,
            paddingVertical: 5,
            // borderRadius: 5,

        },

    });
    //STYLES


    return (
        <View style={[styles.container, {
            width: width,
            position: 'absolute',
            bottom: 15,
            // left: 0,
            // right: 0,
            zIndex: 10,
            paddingHorizontal: 10,
            height: width > 1000 ? 60 : 45,
            alignSelf: 'center',

            //flexDirection: 'row',
            justifyContent: 'center',
            // backgroundColor: colours.white,

            // shadowColor: colours.black,
            // shadowOffset: { width: 0, height: 3, },
            // shadowOpacity: 0.2,
            // shadowRadius: 20,
            // elevation: 5,

        }]}>
            <View style={[styles.containerCenter, {
                maxWidth: 1280, width: width > 1000 ? '85%' : '95%',
            }]}>


                <View>



                    {/* {width < 1000  && */}

                    {/* // {width < 1000 && pageNumber > 0 && */}
                    <View style={styles.containerMenuMobile}>

                        {/* <View style={styles.containerMenuLeft}>
                            <TouchableOpacity style={styles.submitButton} onPress={() => Linking.openURL('tel:+971507893788')} >
                                    <Image source={Mobile} style={{ width: 40, height: 40 }} />
                                </TouchableOpacity>
                            </View>
                            
                            <TouchableOpacity style={styles.submitButton} onPress={() => Linking.openURL('mailto:info@bquae.com')} >
                                    <Image source={Form} style={{ width: 40, height: 35, resizeMode:'contain', marginBottom:5,}} />
                                </TouchableOpacity> */}

                        {/* <View style={styles.containerMenuRight}> */}
                        <TouchableOpacity style={styles.submitButton} onPress={() => Linking.openURL('https://api.whatsapp.com/send?phone=971507893788')} >
                            <View> <Image source={WhatsApp} style={{ width:width>1275? 50:35, height:width>1275? 50:35 }} /></View>
                        </TouchableOpacity>
                        {/* </View> */}

                    </View>



                </View>




                <Modal
                    isVisible={isModalVisibleContact}
                    transparent={true}
                    onBackdropPress={() => setModalVisibleContact(!isModalVisibleContact)}
                    animationIn={'slideInLeft'}
                    animationOut={'slideOutLeft'}
                    style={styles.modalContainer}
                >
                    <View>
                        <Menu
                            toggleModal={toggleModal}
                        />
                    </View>
                </Modal>


            </View>
        </View>

    );
};






export default Floating;