import React, { useState, useEffect, useContext, useRef, useLayoutEffect } from 'react';
import { StyleSheet, View, ScrollView, Image, Text, Animated, TouchableOpacity, Dimensions, SafeAreaView, Linking } from 'react-native';

import colours from '../../config/colours';
import Header from '../../components/header'
import Footer from '../../components/footer'
import { useNavigation } from '@react-navigation/native';
import fonts from '../../config/fonts';

import WhatsApp from '../../assets/images/social/WhatsappIcon.svg';
import Call from '../../assets/images/social/call.svg';
import Mail from '../../assets/images/social/mail.svg';
import ContactSocial from './contactSocial';
import LeadFormFill from '../promotions/leadFormFill';



const { height, width } = Dimensions.get('window')

const Contact = ({ route }) => {


    const [dimensions, setDimensions] = React.useState({ height: window.innerHeight, width: window.innerWidth })
    const handleResize = () => { setDimensions({ width: window.innerWidth, height: window.innerHeight, }); }
    const { width, height } = dimensions;

    useEffect(() => {
        window.scrollTo(0, 0),
            window.addEventListener("resize", handleResize, false)
        return () => window.removeEventListener("resize", handleResize)

        //firebase.analytics();
        // eslint-disable-next-line
    }, [width, height]);

    const [pageNumber, setPageNumber] = useState(0)
    const offset = useRef(new Animated.Value(0)).current;

    const navigation = useNavigation();



    const styles = StyleSheet.create({
        containerNew: {
            //backgroundColor: '#5533a2',
            //backgroundColor: '#c724b1',
            backgroundColor: colours.primaryDark,
            alignSelf: 'center',
            paddingHorizontal: 10,
            paddingVertical: 5,
            borderRadius: 15,
            position: 'absolute',
            top: 10,
            right: -25,
        },
        containerOverlay: {
            flexDirection: 'row',
            alignSelf: 'left',
            paddingHorizontal: 10,
            paddingVertical: 5,
            position: 'absolute',
            bottom: 0,
            right: 0,
            //right: -45,
        },
        textNew: {
            color: colours.white,
            fontSize: width > 1275 ? 15 : 12,
            fontFamily: fonts.primary,
            fontWeight: '600'
        },
        textNew2: {
            color: colours.white,
            fontSize: width > 1275 ? 14 : 11,
            fontFamily: fonts.primary,
            fontWeight: '500',
        },

        containerCard: {
            flex: 1,
            borderRadius: 5,
            backgroundColor: colours.white,
            width: width > 1275 ? 250 : 200,
            height: width > 1275 ? 320 : 250,
            marginHorizontal: width > 1275 ? 15 : 3,
            marginBottom: 20,
            borderWidth: 1,
            borderColor: colours.primaryLightest,
            borderRadius: 5,
            padding: 3,
            paddingHorizontal: 5,
        },


        containerCardImage: {
            overflow: 'hidden',
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            height: width > 1275 ? 180 : 120,
        },
        textCardHeading: {
            paddingTop: width > 1275 ? 25 : 20,
            paddingHorizontal: 20,
            paddingBottom: 5,
            color: colours.primaryDark,
            fontSize: width > 1275 ? 30 : 22,
            fontWeight: '500',
            fontFamily: fonts.primary,
            textAlign: 'left',
        },

        textCardTitle: {
            // paddingBottom: 5,
            color: colours.primaryDark,
            fontSize: width > 1275 ? 20 : 16,
            fontWeight: '500',
            fontFamily: fonts.primary,
            textAlign: 'left',
        },
        textCardSubtitleBold: {
            // paddingBottom: 5,
            color: colours.secondary,
            fontSize: width > 1275 ? 18 : 14,
            fontWeight: '500',
            fontFamily: fonts.primary,
            textAlign: 'left',
        },
        textCardSubtitle: {
            paddingHorizontal: 5,
            color: colours.black,
            fontWeight: '300',
            fontSize: width > 1275 ? 16 : 12,
            letterSpacing: 0.3,
            textAlign: 'left',
            fontFamily: fonts.primary,
        },
        containerCardDetailsLineRow: {
            flexDirection: 'row',
            paddingHorizontal: 2
        },

        textHovered: {
            borderBottomWidth: 2,
            borderBottomColor: colours.brand,
            marginBottom: -2,
        },
        containerScrollView: {
            flex: 1,
            marginTop: width > 999 ? pageNumber > 0 ? 60 : 78 : pageNumber > 0 ? 40 : 65
        },
        containerRight: {
            //flex: 1,
            marginVertical: 10,
            paddingVertical: 10,
        },

        containerInsideHeaderRight: {
            borderBottomWidth: 1,
            marginBottom: 7,
            borderColor: colours.primaryLight,
            marginHorizontal: width > 1000 ? 16 : 10,
            // alignSelf:'flex-start'
            // backgroundColor: colours.blueGreyLight,
        },
        textHeader: {
            paddingHorizontal: 10,
            paddingVertical: 5,
            color: colours.secondary,
            fontSize: 20,
            fontWeight: '500',
            fontFamily: fonts.primary,
            textAlign: 'justify',
        },



        textSubmit: {
            paddingHorizontal: 20,
            alignContent: 'center',
            justifyContent: 'center',
            paddingTop: 10,
            fontSize: 20,
            color: colours.white,
            fontFamily: fonts.primary

        },


        containerBody: {
            width: width > 1080 ? 1280 : width,
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignSelf: 'center',
        },

        containerBodyMobile: {
            flex: 1,
            alignItems: 'flex-start'
        },
        containerBodyPrimary: {
            width: width,
            alignSelf: 'center',
            backgroundColor: colours.white

        },
        containerBodySecondary: {
            width: width,
            alignSelf: 'center',
            backgroundColor: colours.white
        },

        containerDetailsRow: {
            width: width > 1275 ? 1280 : width,
            alignSelf: 'center',
            flexDirection: width > 1000 ? 'row' : 'column',
            //backgroundColor:colours.secondary
        },

        containerDetails: {
            flex: 2,
            marginVertical: 15,
            marginHorizontal: width > 1275 ? 15 : 10,
            alignSelf: 'flex-start',
            backgroundColor: colours.white,
            borderRadius: 5,
        },
        containerDetailsMobile: {
            //flex: 2,
            marginVertical: 15,
            marginHorizontal: 10,
            backgroundColor: colours.white,
            borderRadius: 5,
        },
        flatlistcontainer: {
            flexWrap: 'wrap',
            alignContent: 'center'

        },
        containerForm: {
            flex: width > 1280 ? 1 : 1,
            marginVertical: 15,
            marginHorizontal: 15,
            backgroundColor: colours.white,
            borderRadius: 5,
            alignSelf: width > 1275 ? 'flex-start' : 'stretch'
        },




        containerBulletPoints: {
            flexDirection: 'column'
        },
        containerHeaderRow: {
            flexDirection: 'row',
        },
        textH1: {
            paddingTop: width > 1275 ? 25 : 20,
            paddingHorizontal: width > 1275 ? 20 : 20,
            paddingBottom: 5,
            color: colours.secondary,
            fontSize: width > 1275 ? 30 : 22,
            fontWeight: width > 1275 ? '500' : '600',
            fontFamily: fonts.primary,
            textAlign: 'left',

        },
        textH2: {
            paddingTop: width > 1275 ? 25 : 20,
            paddingHorizontal: 20,
            paddingBottom: 5,
            color: colours.primaryDark,
            fontSize: width > 1275 ? 25 : 17,
            fontWeight: '500',
            fontFamily: fonts.primary,
            textAlign: 'left',
        },
        textH3: {
            marginVertical: 0,
            // paddingTop: width > 1275 ? 20 : 10,
            paddingHorizontal: 20,
            paddingBottom: 5,
            color: colours.white,
            fontSize: width > 1275 ? 18 : 13,
            fontWeight: '500',
            fontFamily: fonts.primary,
        },
        textParagraph: {
            paddingHorizontal: 20,
            paddingBottom: 15,
            color: colours.black,
            fontWeight: '300',
            fontSize: width > 1275 ? 16 : 14,
            textAlign: 'left',
            fontFamily: fonts.primary,
            lineHeight: width > 1275 ? 30 : 24,
        },
        textParagraphBold: {
            paddingHorizontal: 20,
           // paddingBottom: 15,
            color: colours.black,
            fontWeight: '300',
            fontSize: width > 1275 ? 16 : 14,
            textAlign: 'left',
            fontFamily: fonts.primary,
            lineHeight: width > 1275 ? 30 : 24,
            letterSpacing: 0.7,
            textAlign: 'justify',
        },
        loadMoreButton: {
            marginVertical: 10,
            //width: '90%',
            flexDirection: 'row',
            backgroundColor: colours.secondaryLight,
            alignSelf: 'center',
            justifyContent: 'center',
            paddingHorizontal: 20,
            paddingVertical: 5,
            borderRadius: 5,
        },

        submitButton: {
            width: 50,
            flexDirection: 'row',
            backgroundColor: '#25D366',
            alignSelf: 'center',
            justifyContent: 'center',
            paddingHorizontal: 20,
            paddingVertical: 5,
            borderRadius: 5,
        },

    });





    return (
        <View style={{ height: height, backgroundColor: colours.secondary }}>
            {/* HEADER SECTION */}
            <Header animatedValue={offset} pageNumber={pageNumber} />

            <ScrollView
                style={styles.containerScrollView}
                contentContainerStyle={{ flexGrow: 1 }}
                //pagingEnabled={true}
                showsVerticalScrollIndicator={false}
                scrollEventThrottle={16}
                onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: offset } } }], { useNativeDriver: false, listener: event => { const offsetY = event.nativeEvent.contentOffset.y; setPageNumber(offsetY / height) } })}
            >
                {/* BANNER SECTION */}
                <View style={styles.containerBodySecondary}>
                    <View style={{ marginVertical: width > 1275 ? 20 : 5 }} />
                    <View style={width > 999 ? styles.containerBody : styles.containerBodyMobile}>

                        <View style={styles.containerHeaderRow}>
                            <Text style={[styles.textH1, { color: colours.primary, fontSize: width > 1275 ? 50 : 25 }]}>
                                {'Contact Us'}
                            </Text>
                        </View>
                    </View>


                </View>

                <View style={styles.containerBodyPrimary}>


                    <View style={styles.containerDetailsRow}>

                        <View style={width > 1275 ? styles.containerDetails : styles.containerDetailsMobile}>


                            <View style={{ marginVertical: width > 1275 ? 20 : 5 }} />

                            {/*HIGHLIGHTS*/}
                            <View style={styles.containerBulletPoints}>
                                <Text style={styles.textH2}>
                                    {'LOCATION - UNITED ARAB EMIRATES'}
                                </Text>

                                <View style={{ marginVertical: 20, paddingLeft: 20 }}>

                                    <View style={styles.containerRight}>
                                        <View style={styles.containerInsideHeaderRight}>
                                            <Text style={styles.textHeader}>{'DUBAI'}</Text>
                                        </View>

                                        <Text style={[styles.textParagraphBold, { color: colours.black }]}>
                                            {'Office 2011, Block B, \nAl Quoz 3 \nDubai, United Arab Emirates'}
                                        </Text>

                                    </View>

                                    {/* <View style={styles.containerRight}>
                                        <View style={styles.containerInsideHeaderRight}>
                                            <Text style={styles.textHeader}>{'ABU DHABI - UAE'}</Text>
                                        </View>

                                        <Text style={[styles.textParagraphBold, { color: colours.black }]}>
                                            {'M205, Oceanscape Tower, \nAl Reem Island,  \nAbu Dhabi, United Arab Emirates'}
                                        </Text>

                                    </View> */}

                                </View>
                            </View>

                            <View style={{ marginVertical: 10, borderBottomColor: colours.secondaryLight, borderBottomWidth: 2 }} />

                            {/*DOWNLOADS*/}
                            <View style={{ marginVertical: 10 }} />
                            <Text style={styles.textH2}>
                                {'Communication'}
                            </Text>
                            {/* <Text style={[styles.textParagraphBold, { color: colours.secondaryDarker }]}>
                                {'ways to stay in touch'}
                            </Text> */}
                            <TouchableOpacity onPress={() => Linking.openURL('mailto:info@bquae.com?subject=Support&body=Hi')} >
                                <View style={{ flex: 1, flexDirection: 'row',marginTop:10,paddingHorizontal:10 }}>
                                    <Image source={Mail} style={{ width: width > 1275 ? 25 : 20, height: width > 1275 ? 25 : 20 }} />
                                    <Text style={[styles.textParagraphBold, { color: colours.secondary,  }]}>
                                        {'info@bquae.com '}
                                    </Text>
                                </View>
                            </TouchableOpacity>



                            <TouchableOpacity onPress={() => Linking.openURL('tel:+971507893788')} >
                                <View style={{ flex: 1, flexDirection: 'row',marginVertical:0,paddingHorizontal:10 }}>
                                    <Image source={Call} style={{ width: width > 1275 ? 25 : 20, height: width > 1275 ? 25 : 20 }} />
                                    <Text style={[styles.textParagraphBold, { color: colours.secondary,  }]}>
                                        {'+971 50 789 3788 '}
                                    </Text>
                                </View>
                            </TouchableOpacity>





                            <TouchableOpacity onPress={() => Linking.openURL('https://wa.me/971507893788')}  >
                                <View style={{ flex: 1, flexDirection: 'row',marginVertical:0,paddingHorizontal:10 }}>
                                    <Image source={WhatsApp} style={{ width: width > 1275 ? 25 : 20, height: width > 1275 ? 25 : 20 }} />
                                    <Text style={[styles.textParagraphBold, { color: colours.secondary,  }]}>
                                        {'Send WhatsApp'}
                                    </Text>
                                </View>
                            </TouchableOpacity>







                            <View style={{ marginVertical: 10 }} />


                        </View>





                        <View style={styles.containerForm}>
                            <Text style={styles.textH2}>
                                {"Let's Start"}
                            </Text>
                            <LeadFormFill pageName={'About'} />
                        </View>

                    </View>


                </View>














                {/* FOOTER SECTION */}
                <Footer animatedValue={offset} pageNumber={pageNumber} />
            </ScrollView>
        </View>

    );
};




export default Contact;




