import React from 'react';
import HomeNavigator from '../pages/homeNavigator';
import { DataProvider } from './dataProvider';


export default function Providers() {
  return (
    <DataProvider>
      <HomeNavigator/>
    </DataProvider>
  );
} 